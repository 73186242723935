<template>
  <b-card>
    <h1>Adjuntar tienda</h1>

    <p>Selecciona las tiendas que deseas adjuntar al pedido automatico</p>

    <Searchbar class="mt-2" @onChange="handleSearch" />

    <ul class="mt-2">
      <li v-for="store in stores" :key="store.id" class="mb-1">
        <b-form-checkbox
          :value="store.id"
          v-model="selectedStores"
          class="d-flex align-items-center cursor-pointer"
        >
          <b-img :src="store.logo" width="40" class="mx-50 cursor-pointer" />
          <span class="cursor-pointer">
            {{ store.name }}
          </span>
        </b-form-checkbox>
      </li>
    </ul>

    <Pagination
      :pagination="storesPagination"
      :handlePagination="handlePagination"
      :loading="loading"
      :hideEntriesPerPage="true"
    />

    <b-button variant="primary" class="mt-2" block @click="handleSave">
      Guardar
    </b-button>
  </b-card>
</template>

<script>
import { mapActions } from "vuex"

import Pagination from "@/@core/components/Pagination.vue"
import Searchbar from "@/@core/components/Searchbar.vue"
import messagesMixin from "@/@core/mixins/messagesMixin"

export default {
  components: {
    Searchbar,
    Pagination,
  },
  mixins: [messagesMixin],
  data() {
    return {
      stores: [],
      selectedStores: [],
      loading: false,
      storesPagination: {},
      automaticRestockingId: this.$route.params.automatic_restocking_id,
      establishmentId: this.$route.params.id,
      entriesPerPage: "10",
    }
  },
  methods: {
    ...mapActions("stores", ["fetchShortStores"]),
    ...mapActions("automaticRestocking", ["updateAutomaticRestocking"]),

    handleSave() {
      if (this.selectedStores.length) {
        this.updateAutomaticRestocking({
          id: this.automaticRestockingId,
          store_ids: this.selectedStores,
        })
      }

      this.successToast("Tiendas adjuntas con exito!")

      this.$router.go(-1)
    },

    handlePagination({ page }) {
      this.getStores({ page })
    },

    handleSearch(query) {
      this.getStores({ query })
    },

    getStores({ query, page } = {}) {
      this.loading = true
      this.fetchShortStores({
        by_establishment: this.establishmentId,
        by_name: query,
        by_excluding_automatic_restocking: this.automaticRestockingId,
        meta: { pagination: { page: page || 1 } },
      })
        .then((res) => {
          this.stores = res.data
          this.storesPagination = res.meta.pagination
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  mounted() {
    this.getStores()
  },
}
</script>
